import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'nameSeparator',
})
export class NameSeparatorPipe implements PipeTransform {
    transform(value: Array<{ id: number; name: string }>, ...args: any[]): any {
        return value
            .map(manager => {
                return manager.name;
            })
            .join(', ');
    }
}
